import React from 'react'
import JoshuaArechigaResume from '../../assets/JoshuaArechigaResume.pdf'
const CTA = () => {
  return (
    <div className='cta'>
      <a href={JoshuaArechigaResume} download className= 'btn'>Download Resume</a>
      <a href ="#contact" className= 'btn btn-primary'>Email me</a>
    </div>
  )
}

export default CTA