import React from 'react'
import './contact.css'
import FadeInSection from '../fadeInSection';
import {MdEmail} from 'react-icons/md'
import {BsLinkedin} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_qcrf6qp', 'template_yl6afhu', form.current, 'roZy00u86l7i35N9i')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
      e.target.reset()
    };
  return (

    <section id ='contact'>
      <h2>/ Contact Me</h2>
      <div className="container contact__container">
        <div className='container contact__options'>
          <article className='contact__option'>
            <MdEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>Joshua.Arechiga.Work@gmail.com</h5>
            <a href='mailto:Joshua.Arechiga.Work@gmail.com' target='_blank'>Send an email</a>
          </article>
          <article className='contact__option'>
            <BsLinkedin className='contact__option-icon'/>
            <h4>Linkedin</h4>
            <h5>Add me on Linkedin</h5>
            <a href='https://www.linkedin.com/in/joshua-arechiga-104445168/' target='_blank'>Visit my profile</a>
          </article>
        </div>
        <form ref = {form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your email' required/>
          <textarea name='message' rows='7' placeholder = 'Your Message' required></textarea>
          <button type ='submit' className= 'btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
    );
  }

export default Contact