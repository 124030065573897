import React from 'react'
import './about.css'
import '../fadeInSection'
import FadeInSection from '../fadeInSection';
import smile from '../../assets/smile.jpg'
import {CgNotes} from "react-icons/cg"
import {GiComputing} from 'react-icons/gi'
import {CgAwards} from 'react-icons/cg'
class About extends React.Component {
    constructor() {
      super();
      this.state = {
        expanded: true,
        activeKey: "1",
        visible: true
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(eventKey) {
      this.setState({
        activeKey: eventKey
      });
    }
    render() {
    return (
        <section id="about">
            <h2><FadeInSection>/ About Me</FadeInSection></h2>
          <div className="container about__container">
            <div className="about__me">
              <div className="about__me-image">
                <img src={smile} alt="About Image">

                </img>
              </div>

            </div>
            <div className="about__content">
              <div className="about__cards">
                <article className='about__card'>
                  <CgAwards className='about__icon'></CgAwards>
                  <h5>3+ degrees awarded</h5>
                </article>
                <article className='about__card'>
                  <CgNotes className='about__icon'></CgNotes>
                  <h5>2+ years of research experience</h5>
                </article>
                <article className='about__card'>
                  <GiComputing className='about__icon'></GiComputing>
                  <h5>3+ years of coding experience</h5>
                </article>
              </div>
              <p>
              Hello everyone, I am a recent graduate from California State University, Northridge where
              I earned a B.S. in Mathematics with a concentration in Statistics. I have a deep passion for Mathematics,
              Artificial Intelligence, Data Science, and everything in-between.
              Currently, I am looking for new opportunities to learn and grow in a professional setting.
              Outside of work, I like to read, workout, play games, and create indie games. If you have any questions,
              reach out to me below and I will respond at my earliest convenience.
              </p>

            </div>
          </div>

        </section>
    );
  }
}

export default About