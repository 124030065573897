import React from 'react'
import './experience.css'
import FadeInSection from '../fadeInSection';

class Experience extends React.Component {
    constructor() {
      super();
      this.state = {
        expanded: true,
        activeKey: "1",
        visible: true
      };
      this.handleSelect = this.handleSelect.bind(this);
    }
    handleSelect(eventKey) {
      this.setState({
        activeKey: eventKey
      });
    }
    render() {
    return (
      <div id="experience">
      <FadeInSection>
        <div className="section-header ">
          <span className="section-title">/ experience</span>
        </div>
      </FadeInSection>
    </div>
      )
    }
}

export default Experience