import React from 'react'
import './footer.css'
import {BsLinkedin} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
const Footer = () => {
  return (
    <footer>
      <a href='#' className='footer__logo'>Joshua Arechiga</a>
      <ul className = 'permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#Skills'>Skills</a></li>
        <li><a href='#projects'>Projects</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className ="footer__socials">
        <a href="https://www.linkedin.com/in/joshua-arechiga-104445168/" target="_blank" rel="noreferrer noopener" ><BsLinkedin/></a>
        <a href="https://github.com/AfkVoid" target="_blank" rel="noreferrer noopener" ><BsGithub/></a>
      </div>
      <div className="footer__copyright">
        <small>&copy; Joshua Arechiga. All rights reserved</small>
      </div>





    </footer>
  )
}

export default Footer