import React from 'react'
import './projects.css'
import dog from '../../assets/Dog.png'
import bee from '../../assets/bee.png'
import wine from '../../assets/wine.jpg'
const data =[
  {
    id: 1,
    image: dog ,
    title: 'Standford Dog Dataset (Neural Networks)',
    github: 'https://github.com/AfkVoid/StanfordDogDataset'
  },
  {
    id: 2,
    image: bee ,
    title: 'Bee Population Project (TimeSeries)',
    github: 'https://github.com/AfkVoid/BeePopulationProject-TimeSeries-'
  },
  {
    id: 3,
    image: wine ,
    title: 'Wine Project',
    github: 'https://github.com/AfkVoid/WineProject'
  }
]
const Projects = () => {
  return (
    <section id='projects'>
    <h2>/ Projects</h2>
      <div className="container portfolio__container">
      {
        data.map(({id, image, title, github}) => {
          return (
            <article className="portfolio__item" >
            <div className="portfolio__item-image">
              <img src ={image} alt={title}/>
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank'>Github</a>
            </div>
          </article> 
          )
        })
      }
      </div>
    
    
    
    
    
    </section>
  )
}

export default Projects