import React from 'react'
import './header.css'
import CTA from './CTA'
import placeholder from '../../assets/placeholder.png'
import profilepicture from '../../assets/profilepicture-removebg.png'
import HeaderSocial from './HeaderSocials'
import FadeInSection from '../fadeInSection'
import Typist from "react-typist";
import "react-typist/dist/Typist.css";

class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey
    });
  }
  render() {
  return (
    <header>
      <div className="container header__container">
        <span className='intro-title'>
          <Typist avgTypingDelay={120}>
            {"Welcome, I'm"}
             <span className="intro-name">{" Joshua."}</span>
          </Typist>
        </span>
          <FadeInSection>
            <div className = "intro-subtitle"> Always learning. Constanly evolving.</div>
          </FadeInSection>
        <CTA/>
        <HeaderSocial/>
        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
    </header>
    );
  }
}

export default Header