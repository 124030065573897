import React from 'react'
import skills from './skills.css'
import FadeInSection from '../fadeInSection'
import {FaDiceD20} from 'react-icons/fa'
class Skills extends React.Component {
      constructor() {
        super();
        this.state = {
          expanded: true,
          activeKey: "1",
          visible: true
        };
        this.handleSelect = this.handleSelect.bind(this);
      }
      handleSelect(eventKey) {
        this.setState({
          activeKey: eventKey
        });
      }
      render() {
      return (
            <section id = 'experience'>
              <h2><FadeInSection>/ Skills</FadeInSection></h2>
              <div className='container experience__container'>
                <div className ="experience__frontend">
                  <h3>Mathematical Skills</h3>
                  <div className = "experience__content">
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Linear Regression</h4>
                        <small className ='text-light'>Advanced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Logistic Regression</h4>
                        <small className ='text-light'>Advanced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Time series</h4>
                        <small className ='text-light'>Experienced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Support Vector Machines </h4>
                        <small className ='text-light'>Experienced</small>
                      </div>  
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Trees</h4>
                        <small className ='text-light'>Intermediate</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Principle Component Analysis</h4>
                        <small className ='text-light'>Intermediate</small>
                      </div>
                    </article>
                  </div>
                </div>
                <div className = 'experience__backend'>
                  <h3>Computer Science Skills</h3>
                  <div className = "experience__content">
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Python</h4>
                        <small className ='text-light'>Advanced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>R</h4>
                        <small className ='text-light'>Advanced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>HTML/CSS</h4>
                        <small className ='text-light'>Experienced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>SQL</h4>
                        <small className ='text-light'>Experienced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>Java/JavaScript</h4>
                        <small className ='text-light'>Experienced</small>
                      </div>
                    </article>
                    <article className = 'experience__details'>
                      <FaDiceD20 className='experience__details-icon'/>
                      <div>
                        <h4>C/C++</h4>
                        <small className ='text-light'>Intermediate</small>
                      </div>
                    </article>
                  </div>
                </div>
              </div>
            </section>
        )     
    }
}

export default Skills